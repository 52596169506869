//
// Utility functions
//

import marked from 'marked';

/**
 * Transforms Markdown to HTML.
 *
 * @param {string} content The Markdown formatted input string.
 * @return {object} Object with __html key for React dangerouslySetInnerHTML.
 */
export function html(content) {
  marked.setOptions({
    gfm: true,
    breaks: true,
  });

  return {
    __html: marked(content),
  };
}

/**
 * Capitalizes the first character of a string.
 *
 * @param {string} str The input string.
 * @return {string} The capitalized string.
 */
export function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}
